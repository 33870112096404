<template>
<div >
  <div class="row" >
        <div class="col-6" v-if="PaymentMethodVorkasse">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Payment Informations</h5>
              <h6 class="card-subtitle mb-2 text-muted">Order ID : {{OrderId}}</h6>
              <ul class="list-group">
                  <li class="list-group-item">IBAN : {{Iban}} </li>
                  <li class="list-group-item">BIC : {{Bic}}</li>
                  <li class="list-group-item">Name : {{Name}}</li>
                  <li class="list-group-item">Amount : {{OrderAmount}}</li>
                  <li class="list-group-item">Reference : {{OrderId}}</li>
                  
              </ul>

            </div>
          </div>
        </div>

        <div class="col-6 qr-container" style="position: relative;">
            <div id="qrcode" style="width:200px; height:200px; margin: auto;position: absolute;top: 0;left: 0;bottom: 0;right: 0;"></div>
        </div>
  </div>
</div>


</template>

<script>

export default {
  props: {
         Iban:'',
         Bic:'',
         Name:''
    },
  
  data() {
    return {
      OrderId:null,
      OrderAmount: null,
      PaymentMethodVorkasse: false,
      
    }
  },

  methods: {

    generateQrCode: function(){
        this.OrderId = parseFloat($.trim($(".widget-order-data div.widget-inner div.h3").text()).split(" ")[1])
        if (!isNaN(this.OrderId )) {
              $.ajax({
                  url: "/Orderwithid",
                  type: 'GET',
                  data: {
                            OrderId: this.OrderId
                      },
                  success: data => {
                    
                      if( data.showqr){
                        this.PaymentMethodVorkasse = true
                        this.OrderAmount = data.totalGross 
            
                          var qrcode = new QRCode(document.getElementById("qrcode"), {
                            width : 200,
                            height : 200
                          });
                          var sepaFormat = "BCD\n001\n1\nSCT\n"+this.Bic+"\n"+this.Name+"\n"+this.Iban+"\nEUR"+this.OrderAmount+"\n\n"+this.OrderId;
                          qrcode.makeCode(sepaFormat);

                      }
                  },
                error: data => {
                          console.log(data)
                    }
            });
        } 
    }


  },
  mounted() {
      this.$nextTick(() => {
        this.generateQrCode()
      })
  },
   created() {
      //let ListpaymentIds = this.paymentIds.split(',').map(Number);
  }
}
</script>

<style lang="scss" scoped>

</style>
